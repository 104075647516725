<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogTabelWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="场站编号:" prop="num" :label-width="formLabelWidth">
                                <el-input v-model="diaform.num"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="场站名称:" prop="cName" :label-width="formLabelWidth">
                                <el-input v-model="diaform.cName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="别名:" prop="alias" :label-width="formLabelWidth">
                                <el-input v-model="diaform.alias"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属机构:" prop="orgNa" :label-width="formLabelWidth">
                                <div @click="selectInstitutions">
                                    <el-input placeholder="请选择" readonly v-model="diaform.orgNa">
                                        <el-button slot="append" icon="el-icon-circle-close" @click.stop="clear"></el-button>
                                    </el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <div class="map_select_box">
                                <div>
                                    <el-form-item label="经度:" prop="lng" :label-width="formLabelWidth">
                                        <el-input v-model="diaform.lng" disabled></el-input>
                                    </el-form-item>
                                    <el-form-item label="纬度:" prop="lat" :label-width="formLabelWidth">
                                        <el-input v-model="diaform.lat" disabled></el-input>
                                    </el-form-item>
                                </div>
                                <div>
                                    <el-button type="primary" plain v-on:click="selectMap">选择经纬度</el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属城市:" prop="bCity" :label-width="formLabelWidth">
                                <el-input v-model="diaform.bCity"></el-input>
                            </el-form-item>
                             <el-form-item label="详细位置:" prop="dAddr" :label-width="formLabelWidth">
                                <el-input v-model="diaform.dAddr"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="场站类别:" prop="sType" :label-width="formLabelWidth">
                                <el-select v-model="diaform.sType" placeholder="请选择" @change="sTypeChange">
                                    <el-option v-for="item in typeList" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                </el-select>
                                <!-- <el-checkbox-group v-model="diaform.sType">
                                    <el-checkbox :label="one.value" v-for="one in typeList" v-bind:key="one.value">{{one.text}}</el-checkbox>
                                </el-checkbox-group> -->
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="泊位数:" prop="berths" :label-width="formLabelWidth">
                                <el-input v-model="diaform.berths"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="isShow">
                      <el-col :span="12">
                        <el-form-item label="场站规模:" prop="type" :label-width="formLabelWidth" :rules="{required: diaform.sType == 0,message:'请选择场站规模',trigger: 'blur'}">
                          <el-select v-model="diaform.type" placeholder="请选择">
                                    <el-option v-for="item in types" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="线路:" prop="routeIds" :label-width="formLabelWidth">
                          <el-select v-model="diaform.routeIds" multiple placeholder="请选择">
                                    <el-option v-for="item in routeList" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="占地面积:" prop="loArea" :label-width="formLabelWidth">
                                <el-input v-model="diaform.loArea"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="建筑面积:" prop="buildArea" :label-width="formLabelWidth">
                                <el-input v-model="diaform.buildArea"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="diaform.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <map-select ref="mapSelect" @getLngLat="getLngLat"></map-select>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>
<script>
const typeList = [
  {
    text: '停车场站',
    value: '0'
  },
  {
    text: '维修场站',
    value: '1'
  },
  {
    text: '充电场站',
    value: '2'
  },
  {
    text: '加油场站',
    value: '3'
  }
]

const types = [{
  text: '主场站',
    value: '0'
  },
  {
    text: '副场站',
    value: '1'
  }
]
import mapSelect from '@/components/pages/admin/basicInformation/siteManagement/mapSelect.vue'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
  data() {
    return {
      // 对话框类型
      type: '',
      title: '',
      routeList: [],
      isShow: false,
      diaform: {
        orgId: '',
        orgNa: '',
        num: '',
        cName: '',
        alias: '',
        sType: '',
        lng: '',
        lat: '',
        bCity: '',
        dAddr: '',
        remark: '',
        berths: '',
        loArea: '',
        buildArea: '',
        routeIds: [],
        type: ''
      },
      dialogVisible: false,
      formLabelWidth: '100px',
      typeList,
      types,

      rules: {
        num: [
          {
            required: true,
            message: '请输入场站编号',
            trigger: 'blur'
          }
        ],
        cName: [
          {
            required: true,
            message: '请输入场站名称',
            trigger: 'blur'
          }
        ],
        orgNa: [
          {
            required: true,
            message: '请选择所属机构',
            trigger: 'change'
          }
        ],
        sType: [
          {
            required: true,
            message: '请选择场站类别',
            trigger: 'change'
          }
        ],
        lng: [
          {
            required: true,
            message: '请输入经度',
            trigger: 'blur'
          }
        ],
        lat: [
          {
            required: true,
            message: '请输入纬度',
            trigger: 'blur'
          }
        ],
        bCity: [
          {
            required: true,
            message: '请输入所属城市',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  components: {
    mapSelect,
    institutionsTree
  },
  activated() {
  },
  created() {
    this.getRouteList()
  },
  watch: {
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
      }
    }
  },
  methods: {
    // 打开对话框
    init(option, type) {
      this.dialogVisible = true
      this.isShow = false
      this.type = type || 'add'
      if (this.type == 'update') {
        this.title = '编辑'
        this.$nextTick(() => {
          this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
          if(this.diaform.sType === "0") {
            this.isShow = true
          }
        })
      } else {
        this.title = '新增'
      }
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.diaform.orgId = ''
    },

    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          let url = ''
          if (this.type == 'add') {
            url = '/station/add'
          } else {
            url = '/station/update'
          }

          this.diaform.routeIds = this.diaform.routeIds.join(",")
          let option = JSON.parse(JSON.stringify(this.diaform))
          console.log(option)
          console.log(option)
          this.$http.post(url, option).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    },
    sTypeChange(val) {
      if(val == 0) {
        this.isShow = true
      } else {
        this.isShow = false
        this.diaform.type = ''
        this.diaform.routeIds = []
      }
    },
    // 选择机构树
    selectInstitutions() {
        this.$refs.institutions.init()
    },
    // 上级机构查询
    addInstitutions(data) {
        console.log(data)
        this.diaform.orgId = data.orgId
        this.diaform.orgNa = data.orgNa
    },
    clear() {
        this.diaform.orgId = ''
        this.diaform.orgNa = ''
    },
    selectMap() {
        if (this.diaform.lng && this.diaform.lat) {
            let newLngLat = this.$util.wgs84tobd09(Number(this.diaform.lng),Number(this.diaform.lat))
            var option = {
                lng: newLngLat[0],
                lat: newLngLat[1],
            }
            this.$refs.mapSelect.init(option)
        }else{
            this.$refs.mapSelect.init()
        }
    },
    getLngLat(lng, lat) {
        let newLngLat = this.$util.bd09towgs84(lng*1,lat*1)
        this.diaform = {
            ...this.diaform,
            lng:newLngLat[0],
            lat:newLngLat[1],
        }
        this.$nextTick(()=>{
            this.$refs.dialogForm.validateField(['lng','lat'])
        })
    },
    getRouteList() {
        let url = '/route/info/queryRouteInfoByOrgIds'
          this.$http.post(url).then(({ detail }) => {
            detail.forEach(one => {
                this.routeList.push({
                text: one.cName,
                value: one.routeId
              })
            })
        })
    },
  }
}
</script>
<style lang="scss" scoped="depotManagement">
.map_select_box {
  display: flex;
  align-items: center;

  > div {
    &:nth-of-type(1) {
      flex: 1;
    }

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }
}
</style>
